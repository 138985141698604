<t4-list-view #listVw [options]="listOptions" (onDataRequest)="setListItems()">
  <ng-container *t4ListItem="let item">
    <ng-container>
      <td class="p-6">#{{item.New.Id}}</td>
      <td class="p-6">{{item.New.Created | localDate}}</td>
      <td class="p-6">{{item.New.UserCalculatedName | replaceOnEmpty }}</td>
      <td class="p-6 changes">{{calculateLineChanges(item.New) | uppercase }}</td>
      <td class="p-6">{{item.Old.NewPrice | currency: 'EUR' | replaceOnEmpty}}</td>
      <td class="p-6">{{item.New.NewPrice | currency: 'EUR' | replaceOnEmpty}}</td>
      <td class="p-6 btn-grid">
        <button (click)="openRevisionDialog(item.New)" class="btn btn-primary btn-grid mdi mdi-eye">
          {{'T4.ORDERMUTATIONS.HEADER.SHOW' | translate | uppercase}}
        </button>
      </td>
    </ng-container>
  </ng-container>
</t4-list-view>
