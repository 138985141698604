<div class="row modal-bar border-bottom-0 mb-0">

    <div class="col-sm-6">
        <h5 class="modal-title pb-0">{{'COMPONENTS.ORDER.EDIT-ORDER.MODAL-TITLE.EDIT-PRICE' | translate}}</h5>
    </div>

    <div class="col-sm-6">

        <div class="modal-actions">
            <div class="modal-close align-items-center align-middle f-16">
                <span>{{'original price' | translate}} = <b>{{ orderLine.Total | currency:'EUR' | replaceOnEmpty }}</b></span>
            </div>
        </div>
    </div>
</div>

<hr>

<div class="d-flex flex-row row align-items-stretch">
    <div class="d-flex flex-column col-md-6 align-items-stretch">
        <form [formGroup]="form" class="form-content">
            <div class="section-wrapper">
                <div class="form-layout">
                    <div class="row mg-b-25" *ngIf="orderLine.ProductType == 1 || orderLine.ProductType == 4 || orderLine.ProductType == 7">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="col-6 w-100 d-inline-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <i class="fas fa-money-bill-wave-alt"></i>
                                        <label class="form-control-label">{{'SALES-ENTITY-MODIFICATION.DIALOG.LABEL.PRICE-VARIATION' | translate | uppercase}}</label>
                                    </div>
                                </div>
                                <div class="col-6 w-100 d-inline-flex align-items-center justify-content-end">
                                    <div class="d-flex align-items-center w-100">
                                        <ng-select name="selectedVariant" formControlName="selectedVariant" [items]="orderLine.PriceVariants" bindLabel="VariationName"
                                            placeholder="{{ SALES-ENTITY-MODIFICATION.DIALOG.PLACEHOLDER.PRICE-VARIATION' | translate }}"
                                            [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="onSelectedPriceVariantChange($event)">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mg-b-25"  *ngIf="orderLine.ProductType == 1 || orderLine.ProductType == 4 || orderLine.ProductType == 7">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="col-6 w-100 d-inline-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <i class="fas fa-wallet"></i>
                                        <label class="form-control-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.USE-CREDIT' | translate | uppercase}}</label>
                                    </div>
                                </div>
                                <div class="col-6 w-100 d-inline-flex align-items-center">
                                    <div class="d-flex align-items-center w-100">
                                        <input name="appliedCredit" formControlName="appliedCredit" type="number" class="form-control form-control-filter mr-1"
                                            placeholder="€..." name="Credit" autocomplete="off" min="0" max="{{customerCredit}}" (change)="updateCredit($event.target.value)">
                                        <span class="flex-none">/ {{customerCredit | currency:'EUR' | replaceOnEmpty}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mg-b-25">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="col-6 w-100 d-inline-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <i class="fas fa-receipt"></i>
                                        <label class="form-control-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.SET-MANUAL-PRICE' | translate | uppercase}}</label>
                                    </div>
                                </div>
                                <div class="col-6 w-100 d-inline-flex align-items-center">
                                    <div class="d-flex align-items-center w-100">
                                        <button class="btn btn-primary w-100 m-0 d-flex align-items-center" (click)="openDialog()">
                                            <i class="fas fa-exchange-alt col-3"></i>
                                            <p class="info-label d-inline text-center col-9">{{'CUSTOM.BUTTON.STATE.LIST.EDIT' | translate }}</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="d-flex flex-column col-md-6 align-items-stretch">
        <div class="d-flex info-highlight mt-3">
            <div class="flex-row w-100">
                <div class="row w-100" *ngIf="orderLine.ProductType == 1 || orderLine.ProductType == 4 || orderLine.ProductType == 7">
                    <div class="col-6 w-100 d-inline-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="pl-12">
                                <p class="info-label">{{'SALES-ENTITY-MODIFICATION.DIALOG.LABEL.NEW-PRICE-VARIATION' | translate | uppercase}}</p>
                            </span>
                        </div>
                    </div>
                    <div class="col-6 w-100 d-inline-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="pl-12">
                                <p class="info-label" [class.font-weight-bold]="selectedVariant.VariationName != null">
                                    {{selectedVariant.VariationName | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | uppercase}}
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row w-100" *ngIf="orderLine.ProductType == 1 || orderLine.ProductType == 4 || orderLine.ProductType == 7">
                    <div class="col-6 w-100 d-inline-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="pl-12">
                                <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.NEW-CREDIT' | translate | uppercase}}</p>
                            </span>
                        </div>
                    </div>
                    <div class="col-6 w-100 d-inline-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="pl-12">
                                <p class="info-label">
                                    <span [class.font-weight-bold]="changedPrice.appliedCredit != null">{{changedPrice.appliedCredit | currency:'EUR' | replaceOnEmpty}}</span>
                                     / {{customerCredit | currency:'EUR' | replaceOnEmpty}}</p>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="col-6 w-100 d-inline-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="pl-12">
                                <p class="info-label">{{'SALES-ENTITY-MODIFICATION.DIALOG.LABEL.NEW-PRICE' | translate | uppercase}}</p>
                            </span>
                        </div>
                    </div>
                    <div class="col-6 w-100 d-inline-flex align-items-center">
                        <div class="d-flex align-items-center w-100">
                            <span class="pl-12 w-100">
                                <p class="info-label d-inline-flex w-100" *ngIf="changedTotal != null">
                                    {{orderLine.Total | currency:'EUR' | replaceOnEmpty}}
                                    <span class="flex-grow-1 text-center">></span>
                                    <span class="font-weight-bold">{{changedTotal | currency:'EUR' | replaceOnEmpty}}</span>
                                </p>
                                <p class="info-label" *ngIf="changedTotal == null">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}</p>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div class="row w-100"> -->
                    <hr class="row w-100 text-dark">
                <!-- </div> -->
                <div class="row w-100">
                    <div class="col-6 w-100 d-inline-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="pl-12">
                                <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.NEW-BALANCE' | translate | uppercase}}</p>
                            </span>
                        </div>
                    </div>
                    <div class="col-6 w-100 d-inline-flex align-items-start">
                        <div class="d-flex align-items-center">
                            <span class="pl-12 d-flex">
                                <span class="info-label col-6 align-self-center">{{0 | currency:'EUR' | replaceOnEmpty}}</span>
                                <app-credit-actions-dropdown *ngIf="passedData" [customer]="passedData.customer"></app-credit-actions-dropdown>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<hr>

<div class="text-center">
    <button class="btn btn-primary" [disabled]="!valid" (click)="save()">
        {{'GENERIC.BUTTON.SAVE' | translate | uppercase}}
    </button>
    <button class="btn btn-dark" (click)="dialogRef.close()">
        {{'GENERIC.BUTTON.CANCEL' | translate | uppercase}}
    </button>
</div>
