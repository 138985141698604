<t4-tabs class="mt-32" (selected)="onTabChange($event)">

    <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.ORDERLINES' | translate}}" id="orderlines">
        <t4-order-lines (editOrderLineOutput)="openEditOrderLineDialog($event)" (creditOrderLineOutput)="creditOrderLine($event)" [isInEditMode]="isInEditMode" [orderLines]="orderDetails.OrderLines" [secondaryTicketingActive]="secondaryTicketingActive" [isIbaActive]="isIbaActive" [isSecondaryShop]="isSecondaryShop" [isSecondaryOrder]="isSecondaryOrder" [hasPaymentLines]="orderDetails.PaymentMethodId != null"></t4-order-lines>
    </t4-tab>
    
    <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.DISCOUNTS' | translate}}" id="discounts">
        <t4-discount-lines (onRemoveDiscountCode)="removeDiscountCode($event)" [isInEditMode]="isInEditMode" [discountLines]="orderDetails.DiscountTotals"></t4-discount-lines>
    </t4-tab>
    
    <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.MEMBERSHIPS' | translate}}" id="subscriptions">
        <t4-subscription-lines [orderDetails]="orderDetails"></t4-subscription-lines>
    </t4-tab>
    
    <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.PAYMENT-HISTORY' | translate}}" id="payment-history">
        <app-payment-overview #paymentOverview></app-payment-overview>
    </t4-tab>
    
    <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.ORDER-MUTATIONS' | translate}}" id="ordermutations">
        <t4-order-mutations *ngIf="orderRevisions" [revisions]="orderRevisions"></t4-order-mutations>
    </t4-tab>
    
    <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.ORIGINAL-ORDERLINES' | translate}}" id="original-orderlines" *ngIf="isSecondaryOrder">
        <t4-original-order-lines *ngIf="secondaryOrderLines" [orderLines]="secondaryOrderLines" [isIbaActive]="isIbaActive"></t4-original-order-lines>
    </t4-tab>
    
</t4-tabs>