import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomerCreditChangeService, CustomerView, ListParameters, OrderLineRevision, OrderManagementService } from "../../api";
import { Router } from "@angular/router";
import { ListViewComponent, ListViewSelectionMode } from "@tymes4-shared";
import { OrderRevisionDetailsComponent } from "../order-revision-details/order-revision-details.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-credit-details-dialog',
    templateUrl: './credit-details-dialog.component.html',
    styleUrls: ['./credit-details-dialog.component.scss']
  })
  export class CreditDetailsDialog implements OnInit {
    public customer: CustomerView;
    public revisions: OrderLineRevision[];

    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7,
        noItemsText: 'T4.CREDITOVERVIEW.MUTATIONLINES.NOLINES',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        renderInCard: false,
        noSpacing: true,
        headers: [
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.MUTATION-NUMBER', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.DATE', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.EDITED-BY', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.CHANGES', hideOn: null, width: 338},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.PRICE-CHANGE', hideOn: null},
            {sortField: null, label: '', hideOn: null}
        ]
      };

    constructor(
        @Inject(MAT_DIALOG_DATA) public passedData: any,
        public dialogRef: MatDialogRef<CreditDetailsDialog>,
        private customercreditChangeService: CustomerCreditChangeService,
        private dialog: MatDialog,
        private router: Router,
        private orderManagementService: OrderManagementService,
        private translate: TranslateService
    ) {}

    ngOnInit() {

    }

    OnDataRequest(e) {
      let parameters: ListParameters = {
        PerPage: e.pageSize,
        Page: e.pageNr
      }
      this.customercreditChangeService.getCustomerCreditChanges(this.passedData.customerId, parameters).subscribe(resp => {
        this.customer = resp.Customer;

        this.listVw.Data = {
          TotalRecords: resp.TotalCount,
          Records: resp.Revisions
        }
      });
    }

    linkToCustomer(cId) {
      let link = "relations/customers/details/" + cId;
      this.dialogRef.close();
      this.router.navigate([link]);
      return false;
    }

    refreshData() {
      this.listVw.refreshData();
    }

  isLineRevision(line) {
    return (line.CreditChangeAction == 3 || line.CreditChange == undefined)
  }

  calculateLineChanges(line) {
    let changes: string = "";

    if (this.isLineRevision(line)) {
      const acceptedKeys = ['AppliedCredit', 'AppliedDiscountId', 'CustomerCalculatedName', 'CustomerPassePartoutId', 'DeliveryMethodId',
        'EntranceId', 'EventPlacementId', 'ManualPrice', 'NewBarcode', 'PriceVariationId'];

      Object.keys(line).filter((key)=> {
            if (line[key] != null && acceptedKeys.includes(key))
            {
                changes += key + ' | ';
            }
        });
        changes = changes.slice(0, changes.length-3)
        .replace('AppliedCredit', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.APPLIED-CREDT'))
        .replace('AppliedDiscountId', this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.INPUT-DISCOUNT'))
        .replace('CustomerCalculatedName', this.translate.instant('REPURCHASE.RESERVATIONS.LIST.HEADER.CUSTOMER'))
        .replace('CustomerPassePartoutId', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.CUSTOMER-PPT'))
        .replace('DeliveryMethodId', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.DELIVERY-METHOD'))
        .replace('EntranceId', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.ENTRANCE'))
        .replace('EventPlacementId', this.translate.instant('SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.SECTION-ROW-SEAT'))
        .replace('ManualPrice', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.MANUAL-PRICE'))
        .replace('NewBarcode', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.NEW-BARCODE'))
        .replace('PriceVariationId', this.translate.instant('ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.PLACEHOLDER.PRICE-VARIANT'));
    }
    else if (line.CreditChangeAction == 0) {
        changes = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.PROFIT');
    }
    else if (line.CreditChangeAction == 1) {
        changes = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.REFUND-CASH');
    }
    else if (line.CreditChangeAction == 2) {
        changes = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.REFUND-CREDIT');
    }
    else {
        changes = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.MANUAL-CREDIT');
    }

    if (line.Reason != undefined && line.Reason != "") {
        changes += (" | " + this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.REASON', {reason: line.Reason}))
    }

    return changes;
}

openRevisionDialog(revision: OrderLineRevision) {
    this.orderManagementService.getOrderLineRevisionDetails(revision.Id).subscribe(resp => {
        this.dialog.open(OrderRevisionDetailsComponent, {
            width: '95%',
            maxWidth: '95%',
            data: {revisions: resp}
        })
    });
}
}
