<form [formGroup]="form">

    <div class="card card-table order-height">

        <div class="card-header">
            <h6>{{'passedData.title' | translate}}</h6>
        </div>

        <div class="py-3 px-6 bd-b">
            <div class="row">
                <div class="info-highlight w-100">
                    <div class="row col-12 m-8 p-0">
                        <div class="col-lg-6 d-flex pl-0">
                            <label class="form-control-label align-self-center mb-0">{{'CREDIT.TRANSACTION.LABEL.AMOUNT' | translate | uppercase}}</label>
                        </div>
                        <div class="col-lg-6 pl-0" *ngIf="passedData.type != 'manual'">
                            <input class="form-control" type="number" min="0" max="{{maxCredit}}" name="CreditChange" formControlName="CreditChange" placeholder="{{'CREDIT.TRANSACTION.PLACEHOLDER.AMOUNT' | translate}}">
                             / {{maxCredit | currency:'EUR'}}
                        </div>
                        <div class="col-lg-6 pl-0" *ngIf="passedData.type == 'manual'">
                            <input class="form-control" type="number" min="0" name="CreditChange" formControlName="CreditChange" placeholder="{{'CREDIT.TRANSACTION.PLACEHOLDER.AMOUNT' | translate}}">
                        </div>

                    </div>

                    <div class="col-12 m-8 pl-0">
                        <div class="col-lg-12 d-flex p-0">
                            <label class="form-control-label">{{'CREDIT.TRANSACTION.LABEL.REASON' | translate | uppercase}}</label>
                        </div>
                        <div class="col-lg-12 p-0">
                            <input class="form-control" name="Reason" formControlName="Reason" placeholder="{{'CREDIT.TRANSACTION.PLACEHOLDER.REASON' | translate}}" maxlength="250">
                        </div>
                    </div>
                </div>

                <div class="row col-12 d-flex m-8 justify-content-center pl-0">
                    <button class="btn btn-primary btn-small mt-1" (click)="submit()" [disabled]="form.invalid">
                        <i class="fas fa-money-bill"></i>
                        {{(passedData.type !== 'manual' ? 'GENERIC.BUTTON.CREDIT.CONFIRM' : 'GENERIC.BUTTON.CREDIT') | translate}}
                    </button>
                    <button class="btn btn-secondary btn-small mt-1" (click)="dialogRef.close(false)">
                        <i class="fas fa-times"></i>
                        {{'GENERIC.BUTTON.CANCEL' | translate}}
                    </button>
                </div>
            </div>

        </div>
    </div>
</form>
