import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CreditChangeAction, CustomerCreditChange, CustomerCreditChangeService } from "../../api";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: 'app-credit-transaction-dialog',
    templateUrl: './credit-transaction-dialog.component.html',
    styleUrls: ['./credit-transaction-dialog.component.scss']
  })
  export class CreditTransactionDialog implements OnInit {
    public form: FormGroup;
    public maxCredit: number;

    constructor(
      @Inject(MAT_DIALOG_DATA) public passedData: any,
      public dialogRef: MatDialogRef<CreditTransactionDialog>,
      private customerCreditChangeService: CustomerCreditChangeService,
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup({
          CreditChange: new FormControl(null),
          Reason: new FormControl(''),
          CustomerId: new FormControl(this.passedData.customerId)
        });
        this.maxCredit = this.passedData.credit;
    }

    submit() {
      const creditChange: CustomerCreditChange = this.form.value;
      if (this.passedData.type == 'revenue')
        creditChange.CreditChangeAction = CreditChangeAction.NUMBER_0
      if (this.passedData.type == 'cash')
        creditChange.CreditChangeAction = CreditChangeAction.NUMBER_1
      if (this.passedData.type == 'bank')
        creditChange.CreditChangeAction = CreditChangeAction.NUMBER_2

      this.customerCreditChangeService.bookCredit(creditChange).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
}
