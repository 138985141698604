<button class="btn btn-sm btn-warning dropdown-toggle border w-100 d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="fas fa-angle-down col-3"></i>
    <span class="info-label d-inline text-center col-9">{{'CUSTOM.BUTTON.STATE.LIST.CHOOSE-ACTION' | translate }}</span>
</button>

<div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start"
    style="position: absolute; transform: translate 3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform; cursor: pointer;">
    <a class="dropdown-item" (click)="openDialog('revenue')">
        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CREDIT.REVENUE' | translate | uppercase}}
    </a>
    <a class="dropdown-item" (click)="openDialog('bank')">
        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CREDIT.BANK' | translate | uppercase}}
    </a>
    <a class="dropdown-item" (click)="openDialog('cash')">
        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CREDIT.CASH' | translate | uppercase}}
    </a>
</div>