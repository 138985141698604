import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-reference-dialog',
  templateUrl: './order-reference-dialog.component.html'
})
export class OrderReferenceDialogComponent implements OnInit {
  public reference: string;
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<OrderReferenceDialogComponent>
  ) { }

  ngOnInit() {
    this.reference = this.passedData.reference;

    this.form = new FormGroup({
      Reference: new FormControl(''),
    });

    const patchObject = {
      Reference: this.reference
    };

    this.form.patchValue(patchObject);
  }

  submit() {
    const o = this.form.value;
    this.dialogRef.close(o.Reference)
  }
}
