import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-price-form',
  templateUrl: './edit-price-form.component.html'
})

export class EditPriceFormComponent implements OnInit {
  public priceLineComponentSelections = [];
  public isReadOnly = false;
  public allowEmptyRate = false;
  public clearComponentPrice = false;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditPriceFormComponent>) { }

  public logoimagecontext: CanvasRenderingContext2D;
  public overviewimagecontext: CanvasRenderingContext2D;

  ngOnInit() {
    this.priceLineComponentSelections = this.passedData.priceLineComponentSelections;
    this.isReadOnly = this.passedData.readOnly;

    if (this.passedData.allowEmptyRate && this.passedData.allowEmptyRate === true) {
      this.allowEmptyRate = true;
    }

    this.clearComponentPrice = !!this.passedData.clearComponentPrice;

    this.priceLineComponentSelections = this.passedData.priceLineComponentSelections;
  }

  onAmountExVATChanged(event, ps) {
    if (event.target.value === '') {
      ps.AmountExVAT = null;
      ps.VAT = 0;
      ps.AmountInVAT = null;
    } else {
      ps.AmountExVAT = +event.target.value;
      ps.VAT = Math.round(ps.AmountExVAT * (ps.VATPercentage / 100) * 100) / 100;
      ps.AmountInVAT = ps.AmountExVAT + ps.VAT;
    }
  }

  onAmountInVATChanged(event, ps) {
    if (event.target.value === '') {
      ps.AmountExVAT = null;
      ps.VAT = 0;
      ps.AmountInVAT = null;
    } else {
      ps.AmountInVAT = +event.target.value;
      ps.VAT = Math.round(((ps.AmountInVAT / (1 + (ps.VATPercentage / 100))) * (ps.VATPercentage / 100)) * 100) / 100;
      ps.AmountExVAT = ps.AmountInVAT - ps.VAT;
    }
  }

  removePrice(ps) {
    ps.AmountExVAT = null;
    ps.AmountInVAT = null;
    ps.VAT = 0;
  }

  submit() {
    this.dialogRef.close(this.priceLineComponentSelections);
  }
}
