import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import localeNl from '@angular/common/locales/nl';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';

import {
  ConfirmModule,
  ConfirmService,
  EnvironmentModule,
  EnvironmentService,
  HttpLoaderService,
  LayoutsModule,
  LoaderModule,
  PipesModule,
  RoutePartsService,
  SnackbarModule,
  SnackbarService,
  TranslateHttpLoader,
  VersionCheckService,
  InjectComponentModule,
  FormExtensionModule,
  UploadMessageModule,
  UploadMessageService,
  NotificationModule,
} from '@tymes4-shared';


import {QuillModule} from 'ngx-quill';
import {AppComponent} from './app.component';

import {rootRouterConfig} from './app.routing';
import {HttpErrorHandler} from './shared/common/errors/http-error-handler';
import {NgxEditorModule} from './shared/components/common/ngx-editor/ngx-editor.module';
import {NavHeaderComponent} from './shared/components/nav-header/nav-header.component';
import {EditPriceFormComponent} from './shared/dialogs/edit-price-form/edit-price-form.component';
import {EditUserFormComponent} from './shared/dialogs/edit-user-form/edit-user-form.component';
import {AuthInterceptor} from './shared/services/auth/auth.interceptor';
import {SharedModule} from './shared/shared.module';
import {StaticSharedModule} from './shared/static-shared.module';
import { MonacoEditorModule } from '@dmlukichev/ngx-monaco-editor'; //Tymes4v2
import {CanDeactivateGuard} from "./shared/components/can-deactivate/can-deactivate.guard";
import { AppCurrencyPipe } from './shared/pipes/currency.pipe';
import { ApiModule } from './shared/api';
import { apiConfigFactory } from './shared/config/api.config.factory';
import { BASE_BO_CONFIG } from './injectors';
import { MaintenanceInterceptor } from './shared/services/auth/maintenance.interceptor';
import { ResultHandler } from './shared/common/result-handler';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeNl, 'nl-NL');

@NgModule({
  imports: [
    [ApiModule.forRoot(apiConfigFactory)],
    NotificationModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    StaticSharedModule.forRoot(),
    NgxEditorModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectModule,
    ConfirmModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHttpLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, EnvironmentService]
      }
    }),

    RouterModule.forRoot(rootRouterConfig, {useHash: false}),
    QuillModule.forRoot(),
    EnvironmentModule,
    SnackbarModule,
    LayoutsModule,
    PipesModule,
    LoaderModule,
    InjectComponentModule,
    FormExtensionModule,
    UploadMessageModule,
    MonacoEditorModule.forRoot() // use forRoot() in main app module only.
  ],
  declarations: [AppComponent,
    AppCurrencyPipe,
    NavHeaderComponent,
  ],
  entryComponents: [EditUserFormComponent,
    EditPriceFormComponent,
    NavHeaderComponent,
  ],
  providers: [

    //TOKEN INTERCEPTOR
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true
    },
    //Global Webservice errorhandler
    {
      provide: ErrorHandler, useClass: HttpErrorHandler
    },
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: LOCALE_ID, useValue: 'nl-NL'},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    AppCurrencyPipe,
    ConfirmService,
    VersionCheckService,
    RoutePartsService,
    TranslateService,
    SnackbarService,
    HttpLoaderService,
    ResultHandler,
    CanDeactivateGuard,
    UploadMessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

