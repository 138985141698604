import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core'
import { ConfirmService, HttpLoaderService, ProductTypeEnum } from '@tymes4-shared';
import { DiscountRejectionReason } from '../../../shared/enums/discount-rejection-reasons';
import { DialogHelper, DialogWidth } from '@tymes4-shared';
import { forkJoin } from 'rxjs';
import { AddDiscountCodeFormComponent } from '../add-discount-code-form/add-discount-code-form.component';
import { EditOrderLineFormComponent } from '../edit-orderline-form/edit-orderline-form.component';
import { EditPriceFormComponent } from '../edit-price-form/edit-price-form.component';
import { SelectTicketCustomerComponent } from '../select-ticket-customer/select-ticket-customer.component';
import { AccountManagerService, DeliveryMethod, PendingOrderService, PlacePendingOrderArgs, DiscountService, PendingOrderLineService, UpdateTicketPriceComponentArgs, PutOrderlinePropertiesArgs, OrderManagementService, OrderPlacementResult, PlaceResult } from '../../api';
import { ApplicationModuleHelperService } from '../../services/application-module.service';
import { GenericErrorHandler } from '../../helpers/generic-error-handler';
import { ResultHandler } from '../../common/result-handler';

interface COChanges {
  amount: number;
  priceComponents: any;
  priceVariationId: number;
}

interface DeliveryMethodExtended extends DeliveryMethod {
  MapsTo?: DeliveryMethodExtended;
}

@Component({
  selector: 'app-confirm-order',
  templateUrl: './confirm-order.component.html',
  styleUrls: ['./confirm-order.component.scss']
})
export class ConfirmOrderComponent implements OnInit, AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<ConfirmOrderComponent>,
    private dialog: MatDialog,
    private apploader: HttpLoaderService,
    private applicationModuleHelperService: ApplicationModuleHelperService,
    private pendingOrderService: PendingOrderService,
    private orderManagementService: OrderManagementService,
    private confirmService: ConfirmService,
    private resultHandler: ResultHandler,
    private genericErrorHandler: GenericErrorHandler,
    private accountManagerService: AccountManagerService,
    private discountService: DiscountService,
    private translateService: TranslateService,
    private pendingOrderLineService: PendingOrderLineService
    ) {
  }

  public form: FormGroup;
  public orderDetails = null;
  public billingCustomer = null;
  public accountManagers: any = null;
  private editingObject = null;
  public saving = false;
  selectedObjects = [];
  pagedOrderLines: Array<any> = [];
  pagedDiscountLines: Array<any> = [];
  pagedSubscriptionLines: Array<any> = [];

  selectableSubscriptions = [];
  subscriptionTypes = [];

  public tarifs = [];
  // availableSeasons = [];
  lastUsedPage = null;
  lastUsedPageForDiscounts = null;
  lastUsedPageForSubscriptions = null;
  subscriptionReadOnly = false;
  validDeliveryMethodSelected = false;
  deliveryMethods: Array<DeliveryMethodExtended> = null;
  // Part of a feature where the result was not what the client wanted. It's not used but the logic stays.
  private additionalDeliveryMethods = [
    //   {
    //   id: -2,
    //   mapsTo: 'E-TICKET',
    //   translationCode: 'DELIVERY-METHOD.DIRECT-DOWNLOAD',
    //   name: 'Direct Download (E-Ticket)'
    // }
  ];

  public invoiceBatchOptions = [{
    Name: this.translateService.instant('SALES.ORDERS.DETAILS.DROPDOWN.PROCESS-IN-INVOICE-BATCH'), Value: '1'
  }, {
    Name: this.translateService.instant('SALES.ORDERS.DETAILS.DROPDOWN.PROCESS-MANUALLY'), Value: '0'
  }];

  @ViewChild('pagerComponent') pagerComponent;
  @ViewChild('pagerComponentForDiscounts') pagerComponentForDiscounts;
  @ViewChild('pagerComponentForSubscriptions') pagerComponentForSubscriptions;
  @ViewChildren('amountInput') amountInputs;

  public ProductTypeEnum = ProductTypeEnum;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pagerComponent.pageSize = 5;
      this.pagerComponentForDiscounts.pageSize = 5;
      this.pagerComponentForSubscriptions.pageSize = 5;

      this.setSearchAndPage(1);
      this.setSearchAndPageForDiscounts(1);
      this.setSearchAndPageForSubscriptions(1);
    });
    this.pendingOrderService.getInvoiceBatchDefault()
      .subscribe(invoiceBatchDefault => {
        this.form.patchValue({
          IncludeInInvoiceBatch: invoiceBatchDefault
        });
      });

  }

  ngOnInit() {

    this.orderDetails = this.passedData.order;

    // If we have any orderlines where the active subscription season is different than the start season it means it's a prolongation.
    if (this.orderDetails.Subscriptions && this.orderDetails.Subscriptions.filter(ol => ol.StartSeason !== ol.ActiveSeason).length > 0) {
      this.subscriptionReadOnly = true;
    }

    this.billingCustomer = this.passedData.customer;

    this.subscriptionTypes = this.passedData.order.SubscriptionTypes ? this.passedData.order.SubscriptionTypes : [];
    this.initializeSubscriptions();
    this.loadDependentFormData();

    const o = {
      OrderId: this.orderDetails.Id,
      AccountManagerId: this.billingCustomer.AccountManagerId,
      Reference: this.orderDetails.Reference,
      PayByInvoice: (this.billingCustomer.CustomerType === 1 ? '1' : '0'),
      DeliveryMethodId: this.orderDetails.DeliveryMethodId,
      IncludeInInvoiceBatch: '1'
    };

    this.form = new FormGroup({
      OrderId: new FormControl(''), // Passed to preserve value
      AccountManagerId: new FormControl({
        value: this.billingCustomer.AccountManagerId,
        disabled: this.billingCustomer.CustomerType !== 1
      }),
      Reference: new FormControl(''),
      PayByInvoice: new FormControl(''),
      DeliveryMethodId: new FormControl(''),
      IncludeInInvoiceBatch: new FormControl('')
    });

    this.form.patchValue(o);
    if (this.orderDetails.DeliveryMethodId) {
      this.onDeliveryMethodChange({ Id: this.orderDetails.DeliveryMethodId });
    }


  }

  loadDependentFormData() {

    this.apploader.open();

    const call0 = this.accountManagerService.listActiveAccountManagers();
    const call1 = this.pendingOrderService.getDeliveryMethods(this.orderDetails.Id);

    forkJoin([call0, call1]).subscribe(([ams, deliveryMethods]) => {
      this.accountManagers = ams;
      const mappedAdditionalDeliveryMethods: Array<DeliveryMethodExtended> = this.additionalDeliveryMethods.map(adm => {
        const target = deliveryMethods.find(dm => dm.Code === adm.mapsTo);
        return {
          Id: adm.id,
          TranslationCode: adm.translationCode,
          Name: adm.name,
          MapsTo: target,
          Code: target.Code,
          DefaultPricingMethodId: target.DefaultPricingMethodId,
          IsPrintDefault: target.IsPrintDefault,
          AvailableUntilHoursBeforeEvent: target.AvailableUntilHoursBeforeEvent,
          AvailableFromHoursBeforeEvent: target.AvailableFromHoursBeforeEvent,
          DefaultEnabled: target.DefaultEnabled,
          Price: target.Price,
          IsSystemMethod: target.IsSystemMethod,
          CreateHardCard: target.CreateHardCard,
          PriceComponentId: target.PriceComponentId,
          PricingMethod: target.PricingMethod,
          PricingMethodName: target.PricingMethodName,
          PricingMethodTranslationCode: target.PricingMethodTranslationCode,
        };
      });
      this.deliveryMethods = deliveryMethods.concat(mappedAdditionalDeliveryMethods);
      this.apploader.close();
    });
  }

  getMappedToDeliveryMethod(dm: DeliveryMethodExtended): DeliveryMethod {
    if (dm.MapsTo == null) {
      return dm;
    }

    return this.getMappedToDeliveryMethod(dm.MapsTo);
  }

  onDeliveryMethodChange(val: DeliveryMethodExtended | { Id: number }) {
    this.validDeliveryMethodSelected = true;
    if (val.hasOwnProperty('Code')) {
      const dm = val as DeliveryMethodExtended;
      const deliveryMethod = this.getMappedToDeliveryMethod(dm);
      this.setDeliveryMethod(deliveryMethod.Id);
    } else {
      this.setDeliveryMethod(val.Id);
    }
  }

  setDeliveryMethod(newId: any) {

    this.apploader.open();
    this.pendingOrderService.putDeliveryMethod(this.orderDetails.Id, newId).subscribe((data: any) => {
      this.apploader.close();
      this.refetchOrderLines();
      this.clearSelection();
    });
  }


  initializeSubscriptions() {
    this.selectableSubscriptions = this.subscriptionTypes;

    const noSub = this.selectableSubscriptions.filter(function (ss) {
      if (ss.Id === 0) { return ss; }
    });

    if (noSub === null || noSub.length === 0) {
      this.selectableSubscriptions.splice(0, 0, { Name: this.translateService.instant('SALES.ORDERS.DIALOG.DROPDOWN.NO-SUBSCRIPTIONS'), Id: 0 });
    }

  }

  doPage(pageArgs) {
    this.setSearchAndPage(pageArgs.pageNr);
  }

  doPageForDiscounts(pageArgs) {
    this.setSearchAndPageForDiscounts(pageArgs.pageNr);
  }

  doPageForSubscriptions(pageArgs) {
    this.setSearchAndPageForSubscriptions(pageArgs.pageNr);
  }

  setSearchAndPage(pageNr: number) {
    this.lastUsedPage = pageNr;
    const length = this.orderDetails.OrderLines.filter(ol => ol.ProductType !== 5).length; // we need a copy
    this.pagerComponent.setObjectCount(length);
    this.pagedOrderLines = this.orderDetails.OrderLines
      .filter(ol => ol.ProductType !== 5)
      .slice((pageNr - 1) * this.pagerComponent.pageSize, (pageNr) * this.pagerComponent.pageSize)
      .map(ol => ({ ...ol, SubscriptionTypeId: ol.SubscriptionTypeId ? ol.SubscriptionTypeId : 0 }));
    this.pagerComponent.setPage(pageNr);
  }

  setSearchAndPageForDiscounts(pageNr: number) {
    this.lastUsedPageForDiscounts = pageNr;
    const length = this.orderDetails.DiscountTotals.length; // we need a copy
    this.pagerComponentForDiscounts.setObjectCount(length);
    this.pagedDiscountLines = this.orderDetails.DiscountTotals
      .slice((pageNr - 1) * this.pagerComponentForDiscounts.pageSize, (pageNr) * this.pagerComponentForDiscounts.pageSize);
    this.pagerComponentForDiscounts.setPage(pageNr);
  }

  setSearchAndPageForSubscriptions(pageNr: number) {
    this.lastUsedPageForSubscriptions = pageNr;
    const length = this.orderDetails.OrderLines.filter(ol => ol.ProductType !== 5).length; // we need a copy
    this.pagerComponentForSubscriptions.setObjectCount(length);
    this.pagedSubscriptionLines = this.orderDetails.OrderLines
      .filter(ol => ol.ProductType !== 5)
      .slice((pageNr - 1) * this.pagerComponentForSubscriptions.pageSize, (pageNr) * this.pagerComponentForSubscriptions.pageSize)
      .map(ol => ({ ...ol, SubscriptionTypeId: ol.SubscriptionTypeId ? ol.SubscriptionTypeId : 0 }));
    this.pagerComponentForSubscriptions.setPage(pageNr);
  }

  removeDiscountCode(codeId: number) {
    this.confirmService.confirm({
      title: this.translateService.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translateService.instant('SALES.ORDERS.DIALOG.MESSAGE.DISCOUNT-WILL-BE-REMOVED')
    }).subscribe((r: boolean) => {
      if (r) {
        this.apploader.open();
        this.discountService.removeDiscountCode(codeId, this.orderDetails.Id).subscribe((order: any) => {
          this.loadOrderLines(order);
          this.apploader.close();
        });
      }
    });
  }

  addDiscountCode() {
    const title = this.translateService.instant('SALES.ORDERS.DIALOG.TITLE.PUT-IN-DISCOUNT');

    const options = DialogHelper.GetDialogOptions(DialogWidth.lg, { title: title });
    const dialogRef: MatDialogRef<any> = this.dialog.open(AddDiscountCodeFormComponent, options);

    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this.apploader.open();
          this.discountService.applyDiscountCode(res.value, this.orderDetails.Id).subscribe((response: any) => {
            this.apploader.close();
            if (response.Success) {
              this.loadOrderLines(response.UpdatedPendingOrder);
            } else {
              let error: string;

              switch (response.Reason) {
                case DiscountRejectionReason.CodeAlreadyUsed:
                  error = 'DISCOUNT.REJECTION-REASON.CODE-ALREADY-USED';
                  break;
                case DiscountRejectionReason.CodeNotFound:
                  error = 'DISCOUNT.REJECTION-REASON.CODE-NOT-FOUND';
                  break;
                case DiscountRejectionReason.DiscountExpired:
                  error = 'DISCOUNT.REJECTION-REASON.DISCOUNT-EXPIRED';
                  break;
                case DiscountRejectionReason.DiscountInactive:
                  error = 'DISCOUNT.REJECTION-REASON.DISCOUNT-NOT-ACTIVE';
                  break;
                case DiscountRejectionReason.DiscountNotFound:
                  error = 'DISCOUNT.REJECTION-REASON.CODE-NOT-FOUND';
                  break;
                case DiscountRejectionReason.DiscountNotStarted:
                  error = 'DISCOUNT.REJECTION-REASON.DISCOUNT-NOT-STARTED';
                  break;
                case DiscountRejectionReason.NotApplicable:
                  error = 'DISCOUNT.REJECTION-REASON.DISCOUNT-NOT-APPLICABLE';
                  break;
                case DiscountRejectionReason.NotStackable:
                  error = 'DISCOUNT.REJECTION-REASON.DISCOUNT-NOT-STACKABLE';
                  break;
                default:
                  error = 'DISCOUNT.REJECTION-REASON.UNKNOWN';
                  break;
              }

              this.confirmService.confirm({
                title: this.translateService.instant('DISCOUNT.REJECTION.TITLE'),
                message: this.translateService.instant(error),
                okonly: true
              }).subscribe((confirmed: boolean) => {
              });
            }
          });
        } else {
          return;
        }
      });
  }

  changeTicketCustomer() {

    if (this.selectedObjects.length === 0) {
      return;
    } // should not be possible

    const title = this.translateService.instant('SALES.ORDERS.DIALOG.TITLE.CHANGE-TICKET-HOLDER');

    const options = DialogHelper.GetDialogOptions(DialogWidth.xxlg, { title: title, payload: this.selectedObjects });
    const dialogRef: MatDialogRef<any> = this.dialog.open(SelectTicketCustomerComponent, options);


    dialogRef.afterClosed()
      .subscribe(updated => {
        if (updated) {
          this.refetchOrderLines();
        } else {
          return;
        }
      }
      );
  }


  editOrderLines() {
    if (this.selectedObjects.length === 0) {
      return;
    } // should not be possible

    const title = this.translateService.instant('SALES.ORDERS.DIALOG.TITLE.CHANGE-ORDER-LINES');

    const options = DialogHelper.GetDialogOptions(DialogWidth.md, { title: title, payload: this.selectedObjects, AllPriceVariants: this.orderDetails.AllPriceVariants });
    const dialogRef: MatDialogRef<any> = this.dialog.open(EditOrderLineFormComponent, options);


    dialogRef.afterClosed()
      .subscribe(updated => {
        if (updated) {
          this.refetchOrderLines();
          this.clearSelection();
        } else {
          return;
        }
      }
      );


  }

  openOrderLinesPriceComponent() {
    if (this.selectedObjects.length === 0) {
      return; // should not be possible
    }

    this.pendingOrderLineService.getPriceComponents(this.selectedObjects[0].Id).subscribe((data: Array<any>) => {
      const title = this.translateService.instant('SALES.ORDERS.DIALOG.TITLE.EDIT-PRICE-STRUCTURE');
      const options = DialogHelper.GetDialogOptions(DialogWidth.md, {
        title: title,
        readOnly: false,
        priceLineComponentSelections: data,
        clearComponentPrice: true
      });
      const dialogRef: MatDialogRef<any> = this.dialog.open(EditPriceFormComponent, options);

      dialogRef.afterClosed()
        .subscribe(lines => {
          if (lines === false) {
            // cancel
          }
          else if (lines === null) {
            // clear prices
            const orderLineIds = this.selectedObjects.map(ol => ol.Id);
            data.forEach(line => {
              line.AmountExVAT = null;
              line.AmountInVAT = null;
              line.VAT = 0;
            });

            var args : UpdateTicketPriceComponentArgs = {
              OrderLineIds: orderLineIds,
              PriceComponents: data
            }

            this.pendingOrderLineService.updateTicketPriceComponents(args).subscribe((result: any) => {
              this.refetchOrderLines();
              this.clearSelection();
            });
          }
          else {
            // store data and update price
            const orderLineIds = this.selectedObjects.map(ol => ol.Id);

            var args : UpdateTicketPriceComponentArgs = {
              OrderLineIds: orderLineIds,
              PriceComponents: lines
            }

            this.pendingOrderLineService.updateTicketPriceComponents(args).subscribe((result: any) => {
              this.refetchOrderLines();
              this.clearSelection();
            });
          }
        });
    });
  }

  openOrderLinePriceComponent(ol) {
    this.pendingOrderLineService.getPriceComponents(ol.Id).subscribe(data => {
      const title = this.translateService.instant('SALES.ORDERS.DIALOG.TITLE.EDIT-PRICE-STRUCTURE');
      const options = DialogHelper.GetDialogOptions(DialogWidth.md, {
        title: title,
        readOnly: false,
        priceLineComponentSelections: data
      });
      const dialogRef: MatDialogRef<any> = this.dialog.open(EditPriceFormComponent, options);

      dialogRef.afterClosed()
        .subscribe(lines => {
          if (lines) {
            // store data and update price
            ol.Total = lines.reduce((prev, curr) => prev + curr.AmountInVAT, 0);
            this.updateLineProperties(ol, { priceComponents: lines } as COChanges);
          }
        }
        );
    });
  }

  refetchOrderLines() {

    this.apploader.open();

    // refetch the details.
    this.pendingOrderService.getPendingOrderDetails(this.orderDetails.Id).subscribe((order: any) => {
      this.loadOrderLines(order);
      this.apploader.close();
    });
  }

  loadOrderLines(order: any) {
    this.orderDetails = order;

    if (this.lastUsedPage != null) {
      this.setSearchAndPage(this.lastUsedPage);
      this.setSearchAndPageForDiscounts(this.lastUsedPageForDiscounts);
      this.setSearchAndPageForSubscriptions(this.lastUsedPageForSubscriptions);
    } else {
      this.setSearchAndPage(1);
      this.setSearchAndPageForDiscounts(1);
      this.setSearchAndPageForSubscriptions(1);
    }
  }

  submit() {
    this.applicationModuleHelperService.getActiveModules().subscribe(
      activeModules => {
        if (activeModules.includes("newordermanagement")) {
          this.placePendingOrderNew();
        }
        else {
          this.placePendingOrderOld();
        }
      },
      error => {
        console.error(error);
        this.placePendingOrderOld();
      }
    )
  }

  private placePendingOrderOld() {
    this.saving = true;
    this.apploader.open();

    const o = this.form.value;

    // gather the entered subscriptions
    const orderLineSubscriptions = [];

    for (const ol of this.orderDetails.OrderLines) {
      // now handled on the server direct: orderLinePrices.push({ OrderLineId: ol.Id, Price: ol.Price, OriginalPrice: ol.OriginalPrice, PriceVariationId: ol.PriceVariationId });
      if (ol.PassePartoutId && !this.subscriptionReadOnly) {
        orderLineSubscriptions.push({ OrderLineId: ol.Id, SubscriptionTypeId: ol.SubscriptionTypeId });
      }
    }

    o.PayByInvoice = o.PayByInvoice === '1';
    o.IncludeInInvoiceBatch = o.IncludeInInvoiceBatch === '1';

    const args: PlacePendingOrderArgs = {
      PendingOrderId: this.orderDetails.Id,
      Reference: o.Reference,
      OrderLinePrices: null,
      OrderLineSubscriptions: orderLineSubscriptions,
      PayByInvoice: o.PayByInvoice,
      AccountManagerId: o.AccountManagerId,
      DeliveryMethodId: null,
      SalesChannelId: null,
      IncludeInInvoiceBatch: o.IncludeInInvoiceBatch === '1',
      CreateCreditInvoice: false
    };

    this.pendingOrderService.placeOrder(args).subscribe(data => {
      this.apploader.close();
      this.dialogRef.close(data);
    });
  }

  private placePendingOrderNew() {
    this.saving = true;
    this.apploader.open();

    const formValue = this.form.value;
    this.orderManagementService.createNewOrder({
      PendingOrderId: this.orderDetails.Id,
      Reference: formValue.Reference,
      PayByInvoice: formValue.PayByInvoice === "1",
      IncludeInInvoiceBatch: formValue.IncludeInInvoiceBatch === "1",
      SendOrderConfirmationMail: false,
      AccountManagerId: formValue.AccountManagerId
    }).subscribe(
      result => {
        this.saving = false;
        this.apploader.close();

        const observable = this.resultHandler.handle(result);
        const returnValue: PlaceResult = { Handled: true, Id: result.Value?.Order?.Id };
        if (observable) {
          observable.subscribe(_ => this.dialogRef.close(returnValue));
        }
        else {
          this.dialogRef.close(returnValue);
        }
      },
      error => {
        this.saving = false;
        this.apploader.close();
        
        if (!this.resultHandler.handle(error?.error)) {
          this.genericErrorHandler.handle(error);
        }
      }
    );
  }

  toggleSelection(orderLine) {

    if (orderLine == null) { return; }

    if (this.isItemSelected(orderLine)) {
      // remove from the selection
      const index = this.selectedObjects.indexOf(orderLine);
      this.selectedObjects.splice(index, 1);
    } else {
      this.selectedObjects.push(orderLine);
    }
  }

  isItemSelected(event) {

    if (!event) { return null; }

    if (this.selectedObjects == null || this.selectedObjects.length === 0) {
      return false;
    }

    const found = this.selectedObjects.filter(function (ep) {
      if (ep.Id === event.Id) {
        return ep;
      }
    });

    return (found != null && found.length > 0);
  }

  allSelectionToggle(toggleOn) {
    // let toggleOn = this.selectedObjects.length != this.orderDetails.OrderLines.length;
    this.selectedObjects = [];
    if (toggleOn) {
      for (const ol of this.orderDetails.OrderLines) {
        this.selectedObjects.push(ol);
      }
    }
  }

  clearSelection() {
    this.selectedObjects = [];
  }

  buttonText() {
    return this.selectedObjects.length != this.orderDetails.OrderLines.length ? this.translateService.instant('SALES.ORDERS.DIALOG.BUTTON.TEXT.SELECT-ALL') : this.translateService.instant('SALES.ORDERS.DIALOG.BUTTON.TEXT.DESELECT-ALL');
  }

  onPriceVariationChange(e: MatSelectChange, ol) {
    const pv = ol.PriceVariants.find(_pv => _pv.VariantId === e.value);
    this.updateLineProperties(ol, { priceVariationId: e.value } as COChanges);
  }

  onInputFieldClick(e) {
    e.stopPropagation();
  }

  updateLineProperties(ol, changes: COChanges) {
    const ids = [ol.Id];
    const args : PutOrderlinePropertiesArgs = {
      SelectedEpIds: ids,
      TicketTextId: -1,
      EntranceId: -1,
      TicketPrice: changes.amount,
      PriceVariationId: changes.priceVariationId,
      PriceComponents: changes.priceComponents
    };

    this.apploader.open();

    this.pendingOrderLineService.putPendingOrderLineProperties(args).subscribe((updatedLine: any) => {

      // update the line
      let index = -1;

      index = this.orderDetails.OrderLines.map(function (e) {
        return e.Id;
      }).indexOf(updatedLine.Id);

      if (index >= 0) {
        this.orderDetails.OrderLines[index] = updatedLine;
      }

      this.setSearchAndPage(this.lastUsedPage);
      this.setSearchAndPageForDiscounts(this.lastUsedPageForDiscounts);
      this.setSearchAndPageForSubscriptions(this.lastUsedPageForSubscriptions);

      // deselect the selection
      this.clearSelection();

      this.apploader.close();

    });
  }

  calculateTotal(property) {
    let result = 0;

    for (const ol of this.orderDetails.OrderLines) {
      result += ol[property];
    }

    return result;
  }

  onSubscriptionTypeChanged(e, ol) {

    if (ol.PassePartoutId === null) {
      return false; // We can't change or set subscriptions on non-passepartout orderlines.
    }

    const ids = [ol.Id];

    const args : PutOrderlinePropertiesArgs = {
      SelectedEpIds: ids,
      TicketTextId: -1,
      EntranceId: -1,
      SubscriptionTypeId: +e.Id
    };

    this.apploader.open();

    this.pendingOrderLineService.putPendingOrderLineProperties(args).subscribe((updatedLine: any) => {

      // update the line
      let index = -1;
      const showMessage = updatedLine !== null;

      index = this.orderDetails.OrderLines.map(function (ev) {
        return ev.Id;
      }).indexOf(updatedLine.Id);

      if (index >= 0) {
        this.orderDetails.OrderLines[index] = updatedLine;
      }

      this.setSearchAndPage(this.lastUsedPage);
      this.setSearchAndPageForDiscounts(this.lastUsedPageForDiscounts);
      this.setSearchAndPageForSubscriptions(this.lastUsedPageForSubscriptions);

      this.apploader.close();

      if (showMessage && e.Id) {
        this.confirmService.confirm({
          title: this.translateService.instant('GENERIC.MESSAGE.WARNING'),
          message: this.translateService.instant('SALES.ORDERS.DIALOG.WARNING.MESSAGE.SUBSCRIPTION-EDITED-WITH-DISCOUNT-SINGLE'),
          okonly: true
        }).subscribe(confirmed => {
        });
      } else if (showMessage && !e.Id) {
        this.confirmService.confirm({
          title: this.translateService.instant('GENERIC.MESSAGE.WARNING'),
          message: this.translateService.instant('SALES.ORDERS.DIALOG.WARNING.MESSAGE.SUBSCRIPTION-EDITED-SINGLE'),
          okonly: true
        }).subscribe(confirmed => {
        });
      }
    });
  }

  getFirstSeasonName(ol) {
    if (ol.SubscriptionTypeId && ol.SubscriptionTypeId !== 0) {
      return ol.SeasonName;
    }

    return '-';
  }

  onSubscriptionTypeForAllChanged(e) {
    this.confirmService.confirm({
      title: this.translateService.instant('GENERIC.MESSAGE.WARNING'),
      message: this.translateService.instant('SALES.ORDERS.DIALOG.WARNING.MESSAGE.CHANGE-SUBSCRIPTION-EDITS-ALL-ORDERLINES')
    }).subscribe(confirmed => {
      if (confirmed) {
        // do it..
        const ids = this.orderDetails.OrderLines.map((item: any) => item.Id);
        const args : PutOrderlinePropertiesArgs = {
          SelectedEpIds: ids,
          TicketTextId: -1,
          EntranceId: -1,
          SubscriptionTypeId: +e.Id
        };

        this.apploader.open();

        this.pendingOrderLineService.putPendingOrderLineProperties(args).subscribe((updatedLine: any) => {
          const showMessage = updatedLine !== null;

          if (showMessage && +e.Id) {
            this.confirmService.confirm({
              title: this.translateService.instant('GENERIC.MESSAGE.WARNING'),
              message: this.translateService.instant('SALES.ORDERS.DIALOG.WARNING.MESSAGE.SUBSCRIPTION-EDITED-WITH-DISCOUNT-MULTIPLE'),
              okonly: true
            }).subscribe(confirmed => {
            });
          } else if (showMessage && !e.Id) {
            this.confirmService.confirm({
              title: this.translateService.instant('GENERIC.MESSAGE.WARNING'),
              message: this.translateService.instant('SALES.ORDERS.DIALOG.WARNING.MESSAGE.SUBSCRIPTION-EDITED-MULTIPLE'),
              okonly: true
            }).subscribe(confirmed => {
            });
          }

          this.apploader.close();

          this.refetchOrderLines();
        });
      }
    });
  }
}
