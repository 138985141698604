import { Component, Input, OnInit, ViewChild} from "@angular/core";
import { OrderLineRevision } from "../../api";
import { ListViewComponent, ListViewSelectionMode } from "@tymes4-shared";
import { MatDialog } from "@angular/material/dialog";
import { OrderRevisionDetailsComponent } from "app/shared/dialogs/order-revision-details/order-revision-details.component";
import { TranslateService } from "@ngx-translate/core";

interface Mutation {
    Old: OrderLineRevision;
    New: OrderLineRevision;
}

@Component({
    selector: 't4-order-mutations',
    templateUrl: './order-mutations.component.html',
    styleUrls: ['./order-mutations.component.scss']
})
export class OrderMutationsComponent implements OnInit {
    @Input() revisions: OrderLineRevision[] = [];
    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7 ,
        noItemsText: 'T4.ORDERMUTATIONS.MUTATIONLINES.NOLINES',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        renderInCard: false,
        noSpacing: true,
        headers: [
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.MUTATION-NUMBER', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.DATE', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.EDITED-BY', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.CHANGES', hideOn: null, width: 338},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.OLD-PRICE', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.NEW-PRICE', hideOn: null},
            {sortField: null, label: '', hideOn: null}
        ]
    };

    constructor(
        private dialog: MatDialog,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(e) {
        if (e.revisions && this.listVw) {
            this.setListItems();
        }
    }

    calculateLineChanges(revision: OrderLineRevision) {
        const mapping = {
            'AppliedCredit': 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.APPLIED-CREDT',
            'CustomerPassePartoutId': 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.CUSTOMER-PPT',
            'EntranceId': 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.ENTRANCE',
            'EventPlacementId': 'SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.SECTION-ROW-SEAT',
            'ManualPrice': 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.MANUAL-PRICE',
            'NewBarcode': 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.NEW-BARCODE',
            'CustomerId': 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.CUSTOMER',
            'PriceVariationId': 'ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.PLACEHOLDER.PRICE-VARIANT',
            'Comment': (comment: string) => this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.REASON', { reason: comment })
        };

        let changes: string[] = [];
        for (const key in mapping) {
            if (revision[key] == null) {
              continue;
            }

            const match = mapping[key];
            const value = typeof match === 'string'
              ? this.translate.instant(match)
              : match(revision[key]);

            changes.push(value);
        }

        return changes.join(" | ");
    }

    setListItems() {
        let mutations: Mutation[] = [];
        for (let i = 1; i < this.revisions.length; i++) {
            mutations.push({
                Old: this.revisions[i],
                New: this.revisions[i-1]
            });
        }

        this.listVw.Data = {
            TotalRecords: mutations.length,
            Records: mutations
        };
    }

    openRevisionDialog(revision: OrderLineRevision) {
        this.dialog.open(OrderRevisionDetailsComponent, {
            width: '95%',
            maxWidth: '95%',
            data: {revisions: this.revisions, selected: revision.Id}
        });
    }
}
