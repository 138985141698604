/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CreditChangeAction = 0 | 1 | 2 | 3;

export const CreditChangeAction = {
    NUMBER_0: 0 as CreditChangeAction,
    NUMBER_1: 1 as CreditChangeAction,
    NUMBER_2: 2 as CreditChangeAction,
    NUMBER_3: 3 as CreditChangeAction
};

