import {Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as FileSaver from 'file-saver';
import {TranslateService} from '@ngx-translate/core';
import {EnvironmentService, RoutePartsService, SessionService, VersionCheckService, InjectComponentService, NotificationCommunicationService, FileService, UploadMessageService, ConfirmService, HttpLoaderService} from '@tymes4-shared';
import {NavHeaderComponent} from './shared/components/nav-header/nav-header.component';
import {AuthService} from './shared/services/auth.service';
import {interval, of, Subscription} from 'rxjs';
import {filter, first} from 'rxjs/operators';
import {KeyValuePair} from './shared/models/key-value-pair';
import { BackofficeConfigModel, Language, LanguageService, LongRunningOperationService, NotificationService, UserDetailsView, UserService, NotificationData, AdditionalDataType, TokenRequestModel, FileImportResult } from './shared/api';
import { BASE_BO_CONFIG } from './injectors';
import { MappingHelper } from './shared/helpers/mapping-helper';
import { FileImportResultDialogComponent } from './shared/dialogs/file-import-result-dialog/file-import-result-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  appTitle = 'Tymes4';
  pageTitle = '';

  private previousSalesChannelId?: number;

  private notificationsCheckRegular$ = interval(2 * 60 * 1000);
  private notificationsCheckRunningLRO$ = interval(5 * 1000);
  private tokenRefesh$ = interval(60000);

  private notificationsSub: Subscription;

  constructor(
    @Inject(BASE_BO_CONFIG) public config: BackofficeConfigModel,
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private versionCheckService: VersionCheckService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private notificationCommunicationService: NotificationCommunicationService,
    private sessionService: SessionService,
    private authService: AuthService,
    private injectComponentService: InjectComponentService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private userService: UserService,
    private longRunningOperationService: LongRunningOperationService,
    private fileHelper: FileService,
    private notificationService: NotificationService,
    private uploadMessageService: UploadMessageService,
    private confirmService: ConfirmService,
    private dialog: MatDialog,
    private loader: HttpLoaderService
  ) {
  }

  ngOnInit() {
    //initiate the notifications check
    //fetch the notifications upon loading the backoffice
    this.checkForNotifications();

    this.subscriptions.push(this.notificationCommunicationService.$notificationUpdateRequired.subscribe(() => this.checkForNotifications()));

    this.subscriptions.push(this.notificationCommunicationService.$notificationAttachmentDownloadRequired.subscribe(notificationId => {
      this.downloadAttachment(notificationId);
    }));

    this.subscriptions.push(this.notificationCommunicationService.$notificationContinuationTriggered.subscribe(notificationId => {
      this.createContinuation(notificationId);
    }));

    this.subscriptions.push(this.notificationCommunicationService.$notificationShowMoreInformation.subscribe(notificationId => {
      this.showMoreInformation(notificationId);
    }));

    this.subscriptions.push(this.notificationCommunicationService.$lroCanceled.subscribe(lroId => {
      this.cancelLro(lroId);
    }));

    this.previousSalesChannelId = parseInt(sessionStorage.getItem('userSelectedChannel'));
    // Translations
    this.languageService.getActiveLanguage(false).subscribe((language: Language) => {
      this.sessionService.setLanguage(MappingHelper.MapAPILanguageToSessionLanguage(language));
      const navHeaderFactory = this.componentFactoryResolver.resolveComponentFactory(NavHeaderComponent);
      this.injectComponentService.injectComponent('navHeaderInjection', navHeaderFactory);
      // Handle page title updates
      this.changePageTitle();

      // Initialize the version check service
      //const config = this.environmentService.loadConfigurations();
      const checkInterval = this.config.versionCheckInterval;

      // if (checkInterval > 0) {
      //   console.log('Running version checked.');
      //   this.versionCheckService.initVersionCheck(this.config.versionCheckURL, checkInterval * 1000);
      // } else {
      //   console.log('Update checking has been disabled.');
      // }

      this.subscriptions.push(this.sessionService.$logOff.subscribe(() => this.authService.logOff()));

      // Reload user on startup
      if (this.sessionService.isLoggedIn()) {
        this.userService.getCurrentUser().subscribe((user: UserDetailsView) => {
          this.sessionService.setUser(MappingHelper.MapUserDetailsViewToAppUser(user));
        }, (error) => this.sessionService.logOff());
      }

      this.subscriptions.push(this.sessionService.$language.subscribe( (language: any) => {
        if (!language) {
          return;
        }
        this.languageService.getTranslations(language.Code, false).subscribe((langDict: { [key: string]: string; }) => {
          this.translateService.setTranslation(language.Code, langDict, false);
          this.translateService.use(language.Code).subscribe(() => {
          });
        });
      }));

      this.subscriptions.push(this.sessionService.$activeSalesChannelId.subscribe((newSalesChannelId: number) => {
        if(this.previousSalesChannelId !== newSalesChannelId && newSalesChannelId !== undefined) {
          this.authService.switchToChannel(newSalesChannelId);
          this.previousSalesChannelId = newSalesChannelId;
        }
      }));
      this.subscriptions.push(this.sessionService.$isArchiveMode.subscribe((newMode: boolean) => {
        const isArchiveMode = sessionStorage.getItem('userIsArchiveMode') == "true";
        if(isArchiveMode !== newMode && newMode !== undefined) {
          this.authService.switchArchiveMode(newMode);
        }
      }));
      this.authService.setHasArchiveDB(this.config.HasArchiveDB);
    });

    this.subscriptions.push(this.tokenRefesh$.subscribe(() => this.refreshJWTToken()));
  }

  downloadAttachment(notificationId: number) {

    this.loader.open();

    this.notificationService.downloadNotificationAttachment(notificationId, true, null, "response")
      .pipe(first())
      .subscribe((resp)=> {
        var fileName = resp.headers.get('content-disposition').split('filename=')[1].split(';')[0];
        FileSaver.saveAs(resp.body, fileName);
        this.loader.close();
      });
  }

  createContinuation(notificationId: number) {
    this.notificationService.createNotificationContinuation(notificationId).subscribe(() => {
      this.checkForNotifications();
    });
  }

  showMoreInformation(notificationId: number) {
    this.notificationService.getNotificationAdditionalData(notificationId).subscribe(data => {
      if (!data) {
        return;
      }
      switch(data.Type) {
        case AdditionalDataType.CustomerImportFileResult:
          this.showFileImportResultDialog(data.Data, 'CUSTOMER_IMPORT');
          break;

        case AdditionalDataType.MembershipImportFileResult:
          this.showFileImportResultDialog(data.Data, 'MEMBERSHIP_IMPORT');
          break;

        case AdditionalDataType.ReservationImportFileResult:
        case AdditionalDataType.TagImportFileResult:
          this.showFileImportResult(data.Data, 'GENERIC.SNACKBAR.IMPORT-FAILED', 'UPLOAD.REPURCHASE.IMPORT-FILE.IMPORT.WARNINGS');
          break;

        case AdditionalDataType.DiscountImportFileResult:
          const translationKey = 'DIALOGS.UPLOAD-DISCOUNTCODES-IMPORTFILE.WARNING.TITLE.IMPORT-DISCOUNTCODES';
          this.showFileImportResult(data.Data, translationKey, translationKey);
          break;

        case AdditionalDataType.ExternalTicketsImportFileResult:
          this.showFileImportResult(data.Data, 'GENERIC.SNACKBAR.IMPORT-FAILED', 'DIALOGS.EXTERNAL-TICKETS-IMPORT.WARNING.TITLE');
          break;

        case AdditionalDataType.TranslationImportFileResult:
          this.showFileImportResult(data.Data, 'DIALOGS.TRANSLATION-IMPORT.ERROR.TITLE', 'DIALOGS.TRANSLATION-IMPORT.WARNING.TITLE');
          break;
      }
    });
  }

  checkForNotifications() {

    if (sessionStorage.getItem('jwt') === null) return;

    //unsubscribe from any previous polling interval
    if (this.notificationsSub && this.notificationsSub != null)
      this.notificationsSub.unsubscribe();

    this.notificationService.getNotificationOverview().pipe(first()).subscribe(n => {

      //check if there are pending lros, in that case we need to poll faster
      let pending = false;

      for(let t of n.NotificationsPerType) {
        for (let n of t.Notifications) {
          if (n.LROIsPending) {
            pending = true;
            break;
          }
        }
      }

      let observable = pending ? this.notificationsCheckRunningLRO$ : this.notificationsCheckRegular$;

      //kickoff the polling for the next check
      //*
      this.notificationsSub = observable.subscribe(() => {
        this.checkForNotifications();
      });
      //*/

      this.notificationCommunicationService.setNotifications(n);
    });

  }

  refreshJWTToken() {
    if (sessionStorage.getItem('jwt') === null) return;

    this.userService.refreshToken().subscribe((result: TokenRequestModel) => {
      this.authService.setToken(result.Token);
    });
  }

  cancelLro(lroId: number) {
   this.longRunningOperationService.cancelLongRunningOperation(lroId)
    .pipe(first()).subscribe(l => {
      //reload after the cancel
      this.checkForNotifications();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => this.translateService.instant(part.title))
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  showFileImportResultDialog(result: FileImportResult, dialogName: string)
  {
    if (Array.isArray(result.Errors) && result.Errors.length > 0){
      this.uploadMessageService.confirm({
        title: `DIALOG.${dialogName}.UPLOAD_FILE.FAILED.CONFIRM.TITLE`,
        errors: result.Errors,
        message: `DIALOG.${dialogName}.UPLOAD_FILE.FAILED.CONFIRM.MSG`,
        messageAdditional: `DIALOG.${dialogName}.UPLOAD_FILE.FAILED.CONFIRM.ADDITIONAL`,
        isWarning: false,
        okonly: true
      });
    }
    else if (Array.isArray(result.Warnings) && result.Warnings.length > 0){
      this.uploadMessageService.confirm({
        title: `DIALOG.${dialogName}.UPLOAD_FILE.WARNING.CONFIRM.TITLE`,
        errors: result.Warnings,
        message: `DIALOG.${dialogName}.UPLOAD_FILE.WARNING.CONFIRM.MSG`,
        messageAdditional: `DIALOG.${dialogName}.UPLOAD_FILE.WARNING.CONFIRM.ADDITIONAL`,
        isWarning: true,
        okonly: true
      });
    }
  }

  showFileImportResult(result: FileImportResult, errorsTranslationKey: string, warningsTranslationKey: string) {

    let data: { title: any; message: string; };

    if (Array.isArray(result.Errors) && result.Errors.length > 0) {
      data = {
        title: this.translateService.instant(errorsTranslationKey),
        message: this.buildErrorText(result.Errors)
      };
    }
    else if (Array.isArray(result.Warnings) && result.Warnings.length > 0) {
      data = {
        title: this.translateService.instant(warningsTranslationKey),
        message: this.buildWarningText(result.Warnings)
      };
    }
    else {
      data = {
        title: "Succes",
        message: "Geen problemen gevonden"
      }
    }

    this.dialog.open(FileImportResultDialogComponent, {
      disableClose: false,
      minWidth: '380px',
      maxWidth: '600px',
      data: data
    });
  }

  buildErrorText(errors: string[]) {
    let result = '<div><b>' + this.translateService.instant('GENERIC.UPLOAD.FAILED') + '</b></div><br />';
    let errorCounter = 0;
    for (const e of errors) {
      if (errorCounter >= 5) {
        result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;` + this.translateService.instant('GENERIC.UPLOAD.MORE.ERRORS.COUNT', {errors : (errors.length - errorCounter)}) + `</span></div><br />`;
        break;
      }

      result += `<div style='color:red;'><span><i class="fas fa-exclamation-circle"></i>&nbsp;${this.translateService.instant(e)}</span></div><br />`;
      errorCounter++;
    }
    return result;
  }

  buildWarningText(warnings: string[]) {
    let result = '<div><b>' + this.translateService.instant('GENERIC.UPLOAD.WARNINGS') + '</b></div><br />';
    let warningCounter = 0;
    for (const e of warnings) {
      if (warningCounter >= 5) {
        result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;` + this.translateService.instant('GENERIC.UPLOAD.MORE.WARNING.COUNT', {count: (warnings.length - warningCounter)}) + `.</span></div><br />`;
        break;
      }

      result += `<div style='color:orange;'><span><i class="fas fa-exclamation-triangle"></i>&nbsp;${this.translateService.instant(e)}</span></div><br />`;
      warningCounter++;
    }
    return result;
  }
}
