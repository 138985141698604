<div class="row modal-bar">
    <div class="col-sm-6">
        <h5 class="modal-title">{{ 'CREDIT.DETAILDIALOG.TITLE' | translate }}</h5>
    </div>
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">x</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="d-flex" *ngIf="customer != undefined">
    <div class="info-highlight d-inline-flex">
        <div class="h-56 d-flex">
            <i class="fas fa-user user-large align-self-center"></i>
        </div>
        <div>
            <div class="row">
                <a href="relations/customers/details/{{customer.Id}}">
                    <span *ngIf="customer.MiddleName != null">{{customer.FirstName}} {{customer.MiddleName}} {{customer.LastName}}</span>
                    <span *ngIf="customer.MiddleName == null">{{customer.FirstName}} {{customer.LastName}}</span>
                    <span *ngIf="customer.Age"> ({{customer.Age}})</span>
                </a>
            </div>
            <div class="row">
                {{customer.Email}}
            </div>
            <div class="row">
                {{customer.ExternalId | replaceOnEmpty}} / {{customer.CustomerNumber}}
            </div>
        </div>
    </div>
    <div class=" d-inline-flex flex-grow-1 justify-content-end">
        <div class="credit-actions">
            <div class="row">
                <div class="col-6">
                    {{'GENERIC.BREADCRUMB.SALES.ORDER.CREDIT-OVERVIEW' | translate | uppercase}}
                </div>
                <div class="col-6">
                    <span class="credit border border-success rounded-pill" [ngClass]="customer.TotalCredit >= 0 ? 'border-success' : 'border-danger'">{{ customer.TotalCredit | currency:'EUR' }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-credit-actions-dropdown *ngIf="customer" [customer]="customer" (creditChange)="refreshData()"></app-credit-actions-dropdown>
                </div>
            </div>
        </div>
    </div>
  </div>

<t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">
    <ng-container *t4ListItem="let item">
        <ng-container>
            <td class="p-12">#{{ item.Id}}</td>
            <td class="p-12">{{item.Created | localDateTime}}</td>
            <td class="p-12">{{ item.UserCalculatedName | replaceOnEmpty }}</td>
            <td class="p-12 changes">{{ calculateLineChanges(item) | uppercase }}</td>
            <td class="p-12" [attr.colspan]="!isLineRevision(item) ? 2 : 1">
                <span class="credit border border-success rounded-pill" [ngClass]="item.CreditChangeAction == undefined ? 'border-success' : 'border-danger'">
                    {{ item.CreditChange | currency:'EUR' }}
                </span>
            </td>
            <td *ngIf="isLineRevision(item)" class="d-flex flex-column p-0">
                <button class="btn btn-primary" (click)="openRevisionDialog(item)">
                    <i class="fas fa-eye"></i>
                    {{'T4.ORDERMUTATIONS.HEADER.SHOW' | translate | uppercase}}
                </button>
            </td>
        </ng-container>
    </ng-container>
</t4-list-view>
