<!-- <app-sales-section-selection (onSectionOrderChange)="sectionOrderLinesChanges($event)" [customer]="customer" [orderlineSelection]="[orderLine]" [selectedTab]="positionType">
</app-sales-section-selection> -->
<div class="h-100">
    <div class="card order-height" style="min-height: 500px;">
        <div class="card-header section-selection p-0">
            <div class="row no-gutters">
                <div class="col-sm-8 col-sm-8 pl-5 pt-2">
                <h6>{{'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.HEADER' | translate}}</h6>
                </div>
                <div class="col-sm-4 text-right p-1 ">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-secondary" (click)="setMapMode(true)" [class.active]="inMapMode === true" ><i class="far fa-map"></i></button>
                    <button type="button" class="btn btn-secondary" (click)="setMapMode(false)" [class.active]="inMapMode === false"><i class="far fa-list-alt"></i></button>
                </div>
                </div>
            </div>
        </div>

        <div class="map-holder">
            <ng-container *ngIf="displayVenue === false">
                <div class="p-5" *ngIf="hasVenuePlan === true">{{'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.MSG' | translate}}</div>
            </ng-container>

            <div [class.d-none]="displayVenue === false || inMapMode === false" class="h-100" fxLayoutAlign="center center">
                <div [class.d-none]= "" style="width: 100%;">
                <div class="p-5" *ngIf="hasVenuePlan === false">{{'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.MSG.NOVENUEPLAN' | translate}}</div>
                <app-venue-renderer [class.d-none]= "hasVenuePlan === false" (onSectionClicked)="handleSectionClick($event)"></app-venue-renderer>
                </div>
            </div>


            <div [class.d-none]="displayVenue === false || inMapMode === true">
                <t4-list-view #listVw [options]="listOptions" (onDirectSelection)="selectSection($event)" (onItemSelectionChange)="OnItemSelectionChange($event)" (onDataRequest)="OnDataRequest($event)">
                    <ng-container *t4ListItem="let item">
                        <td>{{ item.Name }}</td>
                        <td>{{ sectionAvailability(item) }}</td>
                    </ng-container>
                </t4-list-view>
            </div>
        </div>
    </div>
    <div class="m-12a">
        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.POSITION.WARNING' | translate}}
    </div>
    <div class="text-center">
        <button class="btn btn-warning" (click)="freePosition()">
            <i class="fas fa-minus-square"></i>
            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.POSITION.FREE' | translate}}
        </button>
        <button class="btn btn-dark" (click)="dialogRef.close()">
            <i class="fas fa-ban"></i>
            {{'GENERIC.BUTTON.CANCEL' | translate | uppercase}}
        </button>
    </div>
</div>
