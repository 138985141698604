import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { EntranceService, PendingOrderLineService, PutOrderlinePropertiesArgs, TicketTextService } from '../../api';

@Component({
  selector: 'app-edit-orderline-form',
  templateUrl: './edit-orderline-form.component.html'
})
export class EditOrderLineFormComponent implements OnInit {
  public form: FormGroup;

  public ticketTexts = [];
  public selectableEntrances = [];
  private selectedOrderLines = null;
  private formObject = null;
  public priceVariants = [];

  public saving = false;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef:  MatDialogRef<EditOrderLineFormComponent>,
    private entranceService: EntranceService,
    private ticketTextService: TicketTextService,
    private pendingOrderLineService: PendingOrderLineService,
    private loader: HttpLoaderService,
    private translate: TranslateService) { }

  ngOnInit() {

    this.selectedOrderLines = this.passedData.payload;
    this.retrieveSelectableEntrances();
    this.retrieveTicketTexts();

    // Create a new object to bound to the form
    this.formObject = {};

    this.form = new FormGroup({
      EntranceId: new FormControl(-1), //select the "niet aanpassen" as default
      TicketTextId: new FormControl(-1), //select the "niet aanpassen" as default
      TicketPrice: new FormControl(""),
      PriceVariationId: new FormControl(-1), //select the "niet aanpassen" as default
    });

    this.form.patchValue(this.formObject);

  }

  retrieveTicketTexts() {

    this.loader.open();


    this.ticketTextService.ticketTextGetAll().subscribe((texts:any) => {

      let tt = texts;

      //add the default
      tt.splice(0, 0, {
        Id: -999,
        Text: this.translate.instant('CUSTOM.BUTTON.STATE.LIST.STANDARD')
      });

      // add no change
      tt.splice(0, 0, {
        Id: -1,
        Text: this.translate.instant('CUSTOM.BUTTON.STATE.LIST.DONT-CHANGE')
      });

      this.ticketTexts = tt;

      this.loader.close();
    });
  }

  retrieveSelectableEntrances() {

    this.loader.open();

    //get the distinct vbb ids
    let parentVbbIds: any = Array.from(new Set(this.selectedOrderLines.map((item: any) => item.ParentVenueBuildingBlockId)));

    parentVbbIds = parentVbbIds.filter(element => element !== null);
    this.entranceService.getAvailableInSections(parentVbbIds).subscribe((entrances:any) => {

      let allEntrances = entrances;

      //add the default
      allEntrances.splice(0, 0, {
        EntranceId: -999,
        EntranceName: this.translate.instant('CUSTOM.BUTTON.STATE.LIST.STANDARD')
      });

      //add no change
      allEntrances.splice(0, 0, {
        EntranceId: -1,
        EntranceName: this.translate.instant('CUSTOM.BUTTON.STATE.LIST.DONT-CHANGE')
      });
      this.selectableEntrances = allEntrances;
      this.loader.close();
      this.retrieveSelectablePriceVariation();

    });
  }

  retrieveSelectablePriceVariation() {

    this.loader.open();
    let allPriceVariations = this.passedData.AllPriceVariants;

    //add no change
    if (allPriceVariations.length > 0 && allPriceVariations[0].VariantId !== -1) {
      allPriceVariations.splice(0, 0, {
        VariantId: -1,
        VariationName: this.translate.instant('CUSTOM.BUTTON.STATE.LIST.DONT-CHANGE')
      });
    }

    this.priceVariants = allPriceVariations;
    this.loader.close();
  }

  submit() {
    const propertyObject = this.form.value;

    this.saving = true;
    this.loader.open();

    // reset to default value
    if (propertyObject.EntranceId == -999) {
      propertyObject.EntranceId = null;
    }
    if (propertyObject.TicketTextId == -999) {
      propertyObject.TicketTextId = null;
    }
    if (propertyObject.PriceVariationId == -999) {
      propertyObject.PriceVariationId = null;
    }

    propertyObject.SelectedEpIds = this.selectedOrderLines.map((item: any) => item.Id);

    const args : PutOrderlinePropertiesArgs = {
      SelectedEpIds: propertyObject.SelectedEpIds,
      TicketTextId: propertyObject.TicketTextId,
      PriceVariationId: propertyObject.PriceVariationId,
      EntranceId: propertyObject.EntranceId,
    };

    // add the selected orderlines to the property object
    this.pendingOrderLineService.putPendingOrderLineProperties(args).subscribe((_: any) => {
      // close this dialog and pass the newly added id to the called
      this.dialogRef.close(true);
      this.saving = false;
      this.loader.close();
    });
  }
}
