import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Result } from "../api";
import { Observable } from "rxjs";
import { ConfirmComponent } from "@tymes4-shared";

@Injectable()
export class ResultHandler {

  constructor(private dialog: MatDialog, private translateService: TranslateService) { }

  public handle(result: Result, dialogWidth: string = '380px'): Observable<any> | null {
    
    var problems = result?.Problems;
    if (!Array.isArray(problems) || problems.length == 0) return null;

    var html = "<ul>";
    for (var problem of problems) {
      var color = problem.IsError ? "red" : "orange";
      var icon = problem.IsError ? "exclamation-circle" : "exclamation-triangle";
      var message = this.translateService.instant(problem.TranslationKey, problem.TranslationArguments);
      html += `<li style="color:${color};"><i class="fas fa-${icon}"></i>&nbsp;${message}</li>`;
    }
    html += "</ul>";

    let dialogRef: MatDialogRef<ConfirmComponent>;

    dialogRef = this.dialog.open(ConfirmComponent, {
      width: dialogWidth,
      disableClose: true,
      panelClass: 'confirmBox',
      data: {
        title: this.translateService.instant("GENERIC.CONFIRM.TITLE.PROBLEMS"),
        message: html,
        okonly: true
      }
    });

    return dialogRef.afterClosed();
  }
}
